<template>
  <div class="average_warp">
    <div class="left_biao">
      <div class="title">训练次数</div>
      <div id="myChart"
           :style="{width: '100%', height: '320rem'}"></div>
    </div>
    <div class="right_text">
      <div v-for="(item,index) in info"
           :key="index">
        <span class="yuan"
              :style="{'background':color[index]}"></span>
        {{item.name}}
        <span class='num'>{{item.value }}次</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      color: [],
      textList: [],
      info: [],
      myChart: undefined
      // modules_name: ['', '模板训练', '教师试卷', '整卷训练', '知识点训练', '知识点弱点训练', '一级考点训练', '二级考点训练', '考点弱点训练', '二级考点弱点训练', '章节训练', '章节弱点训练', '备考模式-自由选题', '学习模式-自由选题', '讲义训练']
    }
  },
  mounted () { },
  methods: {
    randomHexColor () { //随机生成十六进制颜色
      var hex = Math.floor(Math.random() * 16777216).toString(16); //生成ffffff以内16进制数
      while (hex.length < 6) { //while循环判断hex位数，少于6位前面加0凑够6位
        hex = '0' + hex;
      }
      let color = '#' + hex; //返回‘#'开头16进制颜色
      if (this.color.indexOf(color) != -1) {
        return this.randomHexColor()
      }
      return color
    },

    getInfo (list) {
      this.info = []
      this.count = 0
      list.forEach((item, index) => {
        this.color.push(this.randomHexColor())
        this.count += item.typeCountNew
        let obj = {
          // name: this.modules_name[item.type],
          name: item.typeName,
          value: item.typeCountNew
        }
        this.info.push(obj)
      })
      this.drawLine()
    },
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      this.myChart.setOption({
        tooltip: {
          trigger: 'item',
          position: [20, 20]
        },
        legend: {
          top: '5%',
          left: 'center',
          // 配置顶部那个item隐藏,准备自己写颜色配对文字
          show: false
        },
        graphic: {
          type: "text",
          left: "center",
          top: "42%",
          // top: "50%",
          // style: {
          //   text: `总计${this.count}次`,
          //   // text: "总计",
          //   textAlign: "center",
          //   fill: "#333",
          //   fontSize: 24,
          //   fontWeight: 700
          // },
          style: {
            text: `{txt|总计\n} {num|${this.count}次}`,
            rich: {
              txt: {
                color: '#666',
                fontSize: 20 + 'rem',
                padding: 5,
                align: 'center',

              },
              num: {
                color: '#000',
                fontSize: 36 + 'rem',
                fontWeight: 'bold',
                align: 'center',
                padding: 8
              }
            }
          }
        },
        color: this.color,
        series: [
          {
            type: 'pie',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                // 配置鼠标移上去在饼中间显示文字
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.info
          }
        ]
      });
      window.addEventListener('resize', this.resizeChart)
    },
    resizeChart () {
      this.myChart.resize()
    }
  }
}
</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  display: flex;
  position: relative;
  border-right: 1rem solid #e5e5e5;
  .left_biao {
    width: 100%;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    text-indent: 1.5em;
    .title {
      margin-bottom: 18rem;
    }
    #myChart {
    }
  }
  .right_text {
    width: 55%;
    position: relative;
    right: 0;
    bottom: 0;
    div {
      font-size: 24rem;
      margin-top: 29rem;
      &:nth-child(1) {
        margin-top: 68rem !important;
      }
      .yuan {
        width: 20rem;
        height: 20rem;
        display: inline-block;
        border-radius: 50%;
      }
      .num {
        font-weight: 700;
        padding-left: 5rem;
      }
    }
  }
}
</style>