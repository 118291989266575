<template>
  <div class="average_warp">
    <div class="left_biao">
      对比上周的得分率变化(%)
      <p>(“—”表示上周未训练或得分率不变)</p>
    </div>
    <div class="right_text">

      <div v-for="(item,index) in info"
           :key="index">
        <i class="el-icon-minus"
           v-if="Number(item.gap) == 0||item.typeCountNew==0"
           style="color:#37A0F4;font-size:24rem"></i>
        <i class="el-icon-bottom"
           v-else-if="Number(item.gap) < 0"
           style="color:#FF6147"></i>
        <i class="el-icon-top"
           v-else-if="Number(item.gap) > 0"
           style="color:#3DDB91"></i>

        <span v-if='item.typeCountNew!=0'> {{Math.abs(item.gap)}}</span>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: [],
    }
  },
  mounted () { },
  methods: {
    getInfo (info) {
      this.info = info
    }
  }
}
</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  position: relative;
  border-right: 1rem solid #e5e5e5;
  .left_biao {
    width: 100%;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    text-indent: 1.5em;
    margin-top: -5rem;
    margin-bottom: 18rem;
    p {
      font-size: 18rem;
      font-weight: bold;
      color: #999999;
    }
  }
  .right_text {
    position: relative;
    right: 0;
    bottom: 0;
    font-size: 22rem;
    font-weight: 700;
    ::v-deep .el-icon-minus {
      font-size: 22rem;
    }
    div {
      margin-left: 1.5em;
      &:nth-child(1) {
        margin-top: 5rem;
      }
      margin-top: 34rem;
      i {
        font-size: 22rem;
        font-weight: bold;
      }
    }
  }
}
</style>