<template>
  <div class="cishu_warp">
    <SubjectCard ref="SubjectCardRef"
                 @initList="getInfo" />

    <Mytable :tableData="info"
             :showIndex='true'
             :tableColumns="tableColumns"></Mytable>

    <div class="content"
         v-if="ruodianArr.length">
      <div class="title">
        训练次数小于2次的考点
      </div>
      <div class="tabs">
        <div class="tab"
             v-for="(item,index) in ruodianArr"
             :key="index">
          {{item.checkPointDesc}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectCard from '@/views/components/subject_card/indexCp.vue'
import Mytable from '../components/table.vue'
import { getCheckPointWeak } from '@/api/analysis.js'
import { mapState, mapMutations } from 'vuex'


export default {
  data () {
    return {
      tableColumns: [
        { prop: 'checkPointDesc', label: '考点', align: 'left', },
        { prop: 'thisCount', label: '训练次数', align: 'center', isNum: true, color: "#2196F3" },
        { prop: ['thisRate', 'lastRate'], label: '得分率(%)', align: 'center', isRate: true },
        { prop: 'changeRate', label: '得分率变化(%)', align: 'center' }
      ],
      //   subjectList: ['语文', '理科数学', '英语', '物理', '化学', '生物'],
      activeName: '',
      info: [],
      data1: [120, 132, 101, 134, 90, 230, 2910],
      color: ['#FF6147', '#F2BE39', '#2196F3', '#3CDB91', '#6348F7', '#881280'],
      //textList: ['计数原理', '常用逻辑用语', '立体几何初步', '圆锥曲线与方程', '不等式', '基本初等函数II（三角函数）', '算法初步', '空间向量与立体几何', '函数概念与基本初等函数', '概念与统计案例']
    }
  },
  mounted () {
  },
  components: {
    SubjectCard, Mytable
  },
  computed: {
    ruodianArr () {
      let arr = this.info.filter(item => {
        return item.count < 2
      })
      return arr
    },
    ...mapState(['pointData']),

  },
  methods: {
    refresh () {
      this.$refs.SubjectCardRef.refresh()
    },
    getNumber (prop, value, row) {
      return value ? value : 0
    },
    async getInfo (id) {
      if (id) {
        this.id = id
      }

      await this.$parent.getPointData(this.id)
      this.info = this.pointData.data
    },

    // getLabel (item) {
    //   let a = this.$parent.subjectList.find(ele => ele.subject_id == item.subject_id) ? this.$parent.subjectList.find(ele => ele.subject_id == item.subject_id).subject_name : ''
    //   return a
    // }
  }
}
</script>

<style lang="scss" scoped>
.cishu_warp {
  width: 95%;
  margin-left: 2.5%;
}
::v-deep .el-tabs__nav .is-top {
  margin: 0 auto;
  width: 120rem;
  text-align: center;
  font-size: 18rem;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.colors {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10rem;
    .yuan {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin-right: 10rem;
    }
  }
}
.content {
  margin-left: 4%;
  margin-top: 20rem;
  .title {
    font-size: 20rem;
    font-weight: bold;
    color: #000000;
    line-height: 43rem;
    height: 43rem;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tab {
      padding: 0 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #333333;
      line-height: 33rem;
      font-size: 18rem;
      height: 33rem;
      background: #f6f6f6;
      border-radius: 15rem;
      margin-right: 10rem;
      margin-bottom: 15rem;
    }
  }
}

::v-deep .el-table th,
.el-table tr {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 20rem;
}

::v-deep [data-v-2ef680c1] .el-table {
  margin-top: 10rem;
  font-size: 24rem !important;
}
</style>