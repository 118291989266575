<template>
  <div class="cishu_warp">
    <SubjectCard ref="SubjectCardRef"
                 @initList="getInfo" />
    <div style="text-align: center;margin-top:10rem"
         v-if="info.length == 0">
      <img src="@/assets/柱状.png"
           style="width: 400rem;height: 400rem;margin-top:50rem"
           alt="">
    </div>

    <div id="myRuodian"
         v-else
         :style="{width: '100%', height: '500rem'}"></div>

  </div>
</template>

<script>
import SubjectCard from '@/views/components/subject_card/indexCp.vue'
import { mapState, mapMutations } from 'vuex'
import { getCheckPointWeak, getKnowledgeWeak } from '@/api/analysis.js'
export default {
  // props: ['grade'],
  data () {
    return {
      data1: [120, 132, 101, 134, 90, 230, 2910],
      grade: null,
      color: ['#FF6147', '#F2BE39', '#2196F3', '#3CDB91', '#6348F7', '#881280'],
      idx: 0,
      info: [1],
      //柱状图的value
      values: [],
      //前一周
      oldData: [],
      //本周
      nowData: [],
      myChart: null
    }
  },
  computed: {
    ...mapState(['knowledageData', 'pointData']),
  },
  components: {
    SubjectCard
  },
  methods: {
    refresh () {
      this.$refs.SubjectCardRef.refresh()
    },
    async getInfo (id) {
      // this.$http({
      //   url: '/api/v1/report/know_weak_compare',
      //   method: 'get',
      //   params: this.$getTimeParams(id, this.$parent.value1, this.$parent.value2)
      // }).then(res => {
      //   this.info = res.data.list

      //   this.values = []
      //   this.oldData = []
      //   this.nowData = []

      //   this.info.forEach(item => {
      //     this.values.push(item.desc)

      //     this.oldData.push(item.rate)

      //     this.nowData.push(item.new_rate)

      //   });
      //   if (this.info.length == 0 && this.myChart) {
      //     this.myChart.dispose()
      //     this.myChart = null
      //   } else if (!this.myChart && this.info.length) {
      //     this.$nextTick(() => {
      //       this.drawLine()
      //     })
      //   }
      // })
      // 高三考点，高一高二知识点
      // let dataInfo
      if (this.grade == 1) {
        //dataInfo = await getCheckPointWeak(this.$getTimeParams(id, this.$parent.value1, this.$parent.value2))
        if (!this.pointData.id) {
          let timer = setTimeout(async () => {
            await this.$parent.getPointData(id)
            this.info = this.pointData.data
            this.getChartData()
            window.clearTimeout(timer)

          }, 500)
        }
        else {
          await this.$parent.getPointData(id)
          this.info = this.pointData.data
          this.getChartData()
        }
      }
      else {
        // this.info = this.knowledageData.data
        //dataInfo = await getKnowledgeWeak(this.$getTimeParams(id, this.$parent.value1, this.$parent.value2))
        if (!this.knowledageData.id) {
          let timer = setTimeout(async () => {
            await this.$parent.getKnowledageData(id)
            this.info = this.knowledageData.data
            this.getChartData()
            window.clearTimeout(timer)
          }, 500)
        }
        else {
          await this.$parent.getKnowledageData(id)
          this.info = this.knowledageData.data
          this.getChartData()
        }

      }

      //this.info = dataInfo.data
      // this.values = []
      // this.oldData = []
      // this.nowData = []
      // this.info.forEach(item => {
      //   if (this.grade == 1) {
      //     this.values.push(item.checkPointDesc)
      //   }
      //   else {
      //     this.values.push(item.knowledgeDesc)
      //   }
      //   this.oldData.push(item.lastRate)
      //   this.nowData.push(item.thisRate)
      // });
      // if (this.info.length == 0 && this.myChart) {
      //   this.myChart.dispose()
      // } else if (this.info.length) {
      //   this.$nextTick(() => {
      //     this.drawLine()
      //   })
      // }
    },
    getChartData () {
      this.values = []
      this.oldData = []
      this.nowData = []
      this.info.forEach(item => {
        if (this.grade == 1) {
          this.values.push(item.checkPointDesc)
        }
        else {
          this.values.push(item.knowledgeDesc)
        }
        this.oldData.push(item.lastRate)
        this.nowData.push(item.thisRate)
      });
      if (this.info.length == 0 && this.myChart) {
        this.myChart.dispose()
      } else if (this.info.length) {
        this.$nextTick(() => {
          this.drawLine()
        })
      }
    },
    handleClick (e) { },
    clear () {
      if (this.myChart) {
        this.myChart.dispose()
      }
    },
    drawLine () {
      this.clear()
      this.myChart = this.$echarts.init(document.getElementById('myRuodian'))
      //  console.log(this.myChart)
      this.myChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        color: ['#F2BE39', '#2196F3'],
        calculable: true,
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            realtime: true,
            start: 0,
            end: 100
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: this.values
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: 100
          }
        ],
        series: [
          {
            name: '前一周',
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            data: this.oldData,
          },
          {
            name: '本周',
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            data: this.nowData,
          }
        ]
      })
      window.addEventListener('resize', this.resizeChart)
    },
    resizeChart () {
      this.myChart.resize()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  }
}
</script>

<style lang="scss" scoped>
.cishu_warp {
  width: 95%;
  margin-left: 2.5%;
}
::v-deep .el-tabs__nav .is-top {
  margin: 0 auto;
  width: 120rem;
  text-align: center;
  font-size: 18rem;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.colors {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10rem;
    .yuan {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin-right: 10rem;
    }
  }
}
.content {
  margin-left: 4%;
  .title {
    font-size: 16rem;
    font-weight: bold;
    color: #000000;
    line-height: 23rem;
    height: 23rem;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tab {
      padding: 0 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #333333;
      line-height: 30rem;
      font-size: 14rem;
      height: 30rem;
      background: #f6f6f6;
      border-radius: 15rem;
      margin-right: 10rem;
      margin-bottom: 15rem;
    }
  }
}

::v-deep .el-table th,
.el-table tr {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 20rem;
}
::v-deep .el-table {
  margin-top: 10rem;
}

::v-deep [data-v-2ef680c1] .el-table {
  margin-top: 10rem;
  font-size: 24rem !important;
}
</style>