<template>
  <div class="chartd_warp">
    <TopCard ref="TopCardRef"
             text="学情分析"
             :showBack="true" />
    <el-form label-width="120rem">
      <el-row :gutter="10">
        <el-col :span="10">
          <el-form-item label="本次时间">
            <el-date-picker style="width:100%"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            v-model="value1"
                            :default-time="['00:00:00', '23:59:59']"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">

            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="上次时间">
            <el-date-picker v-model="value2"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            :default-time="['00:00:00', '23:59:59']"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions1">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="explain">
      说明：1.学情报告指对学生的做卷情况、各科弱点、科目整体情况进行分析
    </div>
    <div class="one_warp">
      <div class="title">
        <div>
          一、各类型训练的训练次数与得分率
        </div>
        <!-- <div>
          训练次数及得分率统计
        </div> -->
      </div>
      <div class="biao_warp">
        <div class="sciring_average_warp">
          <SciringAverage ref="SciringAverageRef" />
        </div>
        <div class="center_biaoge">
          <Score ref="ScoreRef" />
        </div>
        <div class="right_biaoge">
          <duibi ref="duibiRef" />
        </div>
      </div>
    </div>

    <!-- 高一高二不显示考点 -->
    <div class="one_warp"
         style="margin-top:24rem;">
      <!-- height:1100rem -->
      <div class="title">
        <div>
          二、各题型训练次数与得分率
        </div>
        <!-- <div>
          各题型训练及得分率统计
        </div> -->
      </div>
      <div class="tabs">
        <cishu ref="cishuRef" />
      </div>
    </div>

    <!-- <div class="one_warp"
         style="margin-top:24rem;"> -->
      <!-- height:980rem -->
      <!-- <div class="title">
        <div>
          三、薄弱考点列表
        </div> -->
        <!-- <div>
          各考点训练及得分率统计
        </div> -->
      <!-- </div>
      <div class="tabs">
        <boruo ref="boruoRef" />
      </div>
    </div> -->

    <div class="one_warp"
         style="margin-top:24rem;">
      <!-- height:980rem -->
      <div class="title">
        <div>
          三、薄弱考点列表
        </div>
        <!-- <div>
          各知识点训练及得分率统计
        </div> -->
      </div>
      <div class="tabs">
        <zhishidian ref="zhishidianRef" />
      </div>
    </div>

    <div class="one_warp"
         style="margin-top:24rem;">
      <!-- height:700rem -->
      <div class="title">
        <div>
          四、弱点训练效果图
        </div>
        <div class="info">
          <!-- 前一周薄弱{{this.userGrade==1?'考点':'知识点'}}与本周对比 -->
          <div style="font-size: 14rem;font-weight: 400;color: #666666;margin-top:0">
            <span>
            </span>
            前一周得分率（%）
            <span>
            </span>
            本周得分率（%）
          </div>
        </div>
      </div>
      <div class="tabs">
        <ruodian ref="ruodianRef" />
        <!-- :grade="userGrade" -->
      </div>
    </div>

    <div class="one_warp"
         style="margin-top:24rem;height:200rem">
      <div class="title"
           style="height:70rem">
        <div>
          五、总结
        </div>
      </div>
      <div class="tabs">
        <zongjie ref="zongjieRef"
                 :value2="value2"
                 :value1="value1" />
      </div>
    </div>
  </div>
</template>

<script>
import SciringAverage from './components/scoringAverage.vue'
import Score from './components/score.vue'
import duibi from './components/duibi.vue'
import cishu from './xunliancishu/index.vue'
import boruo from './boruokaodian/index.vue'
import zhishidian from './boruozhishidian/index.vue'
import ruodian from './ruodian/index.vue'
import zongjie from './zongjie/index.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getModuleData } from '@/api/analysis.js'

import { getStudentSubject } from '@/api/subject.js'
import { mapState, mapMutations } from 'vuex'
import { getCheckPointWeak, getKnowledgeWeak } from '@/api/analysis.js'

export default {
  data () {
    return {
      userGrade: null,//年级
      value1: [],
      value2: [],
      pickerOptions: {

      },
      pickerOptions1: {

      }
    }
  },


  components: {
    SciringAverage, Score, duibi, cishu, boruo, zhishidian, ruodian, zongjie, TopCard
  },

  computed: {
    ...mapState(['subjectList', 'knowledageData', 'pointData']),
    list () {
      return this.subjectList.filter(item => item.subject_id != 13)
    }
  },

  created () {
    this.userGrade = JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id
    this.value1 = this.getRangeTime(30, 0)
    // setTimeout(() => {
    this.value2 = this.getRangeTime(60, 31)
    // }, 50)
  },
  mounted () {
    this.$nextTick(() => {
      // this.publicFun()
    })
    this.getSubjectList()
  },

  watch: {
    value1 () {
      this.publicFun()
    },
    value2 () {
      this.publicFun()
    },
  },

  methods: {
    ...mapMutations(['SET_SUBJECT', 'SET_KNOWLEDGE', 'SET_POINT']),
    async getSubjectList () {
      const { data } = await getStudentSubject()
      this.SET_SUBJECT(data.list)
      this.subjectId = this.subjectList[0].subject_id
    },
    //考点
    async getPointData (id) {

      const { data } = await getCheckPointWeak(this.$getTimeParams(id, this.value1, this.value2))
      let params = { id: id, data: data }
      this.SET_POINT(params)
    },
    //知识点
    async getKnowledageData (id) {

      const { data } = await getKnowledgeWeak(this.$getTimeParams(id, this.value1, this.value2))
      let params = { id: id, data: data }
      this.SET_KNOWLEDGE(params)
    },
    getRangeTime (start, end) {
      let arr = []
      let startTime = new Date()
      let endTime = new Date()
      let n = startTime.getTimezoneOffset() //时差
      arr[0] = new Date(startTime - 3600 * 1000 * 24 * start - n * 60 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      arr[1] = new Date(endTime - 3600 * 1000 * 24 * end - n * 60 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')

      return arr
    },
    publicFun () {
      if (!this.value2.length || !this.value1.length) {
        return
      }
      this.getcishuInfo()
      this.$refs.ruodianRef.grade = this.userGrade
      if (this.subjectId) {
        this.$refs.cishuRef.refresh()
        this.$refs.boruoRef.getInfo()
        this.$refs.zongjieRef.refresh()
        this.$refs.ruodianRef.refresh()
        this.$refs.zhishidianRef.refresh()

        // this.$refs.zongjieRef.getInfo()
      }


    },
    async getcishuInfo () {


      const { data } = await getModuleData({
        thisTimeStart: this.value1[0],
        thisTimeEnd: this.value1[1],
        lastTimeStart: this.value2[0],
        lastTimeEnd: this.value2[1],
      })
      this.$refs.SciringAverageRef.getInfo(data)
      this.$refs.ScoreRef.getInfo(data)
      this.$refs.duibiRef.getInfo(data)
    },
  }
}
</script>

<style lang="scss" scoped>
.chartd_warp {
  padding: 20rem 15rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 40rem);

  background: #f6f6f6;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18rem;
    height: 25rem;
  }
  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-size: 14rem;
  }
  .explain {
    font-size: 22rem;
    font-weight: 500;
    color: #333333;
    line-height: 23rem;
    margin-top: 10rem;
    margin-bottom: 20rem;
    text-indent: 1em;
  }
}
.biao_warp {
  display: flex;
  padding-bottom: 18rem;
  // height: 320rem;
  .sciring_average_warp {
    width: 44%;
  }
  .center_biaoge {
    border-right: 1rem solid #e5e5e5;
    flex: 1;
  }
  .right_biaoge {
    width: 32%;
  }
}

.one_warp {
  width: 99%;
  background: #ffffff;
  box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
  border-radius: 30rem;
  border: 1rem solid #ececec;
  margin: 0 auto;
  padding-bottom: 20rem;
  min-height: 440rem;
  .title {
    // height: 60rem;
    overflow: hidden;
    margin-bottom: 30rem;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        margin-top: 30rem;
        font-size: 24rem;
        font-weight: bold;
        height: 25rem;
        color: #000000;
        line-height: 25rem;
      }
      &:nth-child(2) {
        font-size: 22rem;
        height: 20rem;
        font-weight: bold;
        color: #999999;
        line-height: 20rem;
        margin-top: 20rem;
      }
    }
  }
}
.info {
  position: relative;
  div {
    position: absolute;
    right: 30rem;
    height: 30rem;
    top: -15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin: 0 5rem;
      font-size: 14rem;
      font-weight: 400;
      color: #666666;
      // margin-top: -3rem;
      &:nth-child(1) {
        background: #f2be39;
      }
      &:nth-child(2) {
        background: #2196f3;
      }
    }
  }
}

::v-deep .el-form-item__label {
  font-weight: 500;
  font-size: 22rem;
  color: #333333;
}
::v-deep .el-range-editor--medium.el-input__inner {
  height: 40rem;
  line-height: 40rem;
}
::v-deep .el-range-editor--medium .el-range-input {
  font-size: 20rem;
  font-weight: bold;
  color: #000000;
}
// ::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }
::v-deep .el-icon-time {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-range-editor--medium .el-range-separator {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>