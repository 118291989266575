<template>
  <div class="table_wrap">
    <el-table :data="tableData"
              border
              :fit='true'
              style="width: 100%;min-height:200rem">
      <!-- height="900rem" -->
      <el-table-column type="index"
                       label="序号"
                       align="center"
                       v-if="showIndex"
                       width="140">
      </el-table-column>
      <el-table-column v-for="(item,index) in tableColumns"
                       :key="index"
                       :align="item.align"
                       :label="item.label"
                       :width="item.width?item.width:''">
        <!-- :prop="item.prop" -->
        <template slot-scope="{row}">
          <div v-if="item.isNum"
               :style="{'color':item.color?item.color:''}">
            {{$parent.getNumber(item.prop,row[item.prop],row)}}
          </div>
          <div v-else-if="item.isRate">
            <div v-if='Array.isArray([item.prop])'>
              <div style='display:flex'>
                <div class="line">
                  <div class="this_progress"
                       :style="{'width':row[item.prop[0]]+ '%'} "></div>
                </div>
                <div class="txt">{{row[item.prop[0]]}}</div>
              </div>

              <div style='display:flex'>
                <div class="line">
                  <div class="this_progress"
                       :style="{'width':row[item.prop[1]]+ '%'} "></div>
                </div>
                <div class="txt">{{row[item.prop[1]]}}</div>
              </div>
            </div>

            <div v-else>
              <div style='display:flex'>
                <div class="line">
                  <div class="this_progress"
                       :style="{'width':row[item.prop]+ '%'} "></div>
                </div>
                <div class="txt">{{row[item.prop]}}</div>
              </div>
            </div>
            <!-- {{$parent.getNumber(item.prop,row[item.prop],row)}} -->
          </div>
          <div v-else-if="item.prop=='changeRate'">
            <i class="el-icon-bottom"
               v-if="row['thisRate']-row['lastRate']< 0"
               style="color:#FF6147"></i>
            <i class="el-icon-top"
               v-if="row['thisRate']-row['lastRate'] > 0"
               style="color:#3DDB91"></i>
            <i class="el-icon-minus"
               v-if="row['thisRate']-row['lastRate'] == 0"
               style="color:#37A0F4"></i>
            {{row['thisRate']-row['lastRate'] != 0?Math.abs(row['thisRate']-row['lastRate']):''}}
          </div>
          <div v-else
               class="text_warp"
               :style="{'color':item.color?item.color:''}">
            {{row[item.prop]}}
          </div>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    rowKey: {
      type: String,
      default: ''
    },
    tableColumns: {
      type: Array,
      default: () => {
        return []
      }
    },
    showIndex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //tableColumns: [],
    }
  }

}
</script>

<style lang='scss' scoped>
.table_wrap {
  text-align: center;
  min-height: 440rem;
  .line {
    // height: 16rem;
    width: 75%;
    height: 16rem;
    margin: 10rem 5rem;
    overflow: hidden;
    border-radius: 10rem;
    background: #f6f6f6;
    .this_progress,
    .last_progress {
      height: 16rem;
      border-radius: 15rem;
    }
    .this_progress {
      background: #2196f3;
    }
    .last_progress {
      background: #fbd46f;
    }
  }
  .txt {
    // width: 25%;
    flex: 1;
    margin-left: 15rem;
    line-height: 35rem;
    text-align: left;
  }
}
::v-deep .el-table {
  margin-top: 10rem;
  font-size: 24rem !important;
}
::v-deep .el-table th,
.el-table tr {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 20rem;
  padding: 20rem;
}
::v-deep .el-table td {
  padding: 20rem;
}
::v-deep .el-table__empty-text {
  line-height: 80rem;
}
::v-deep .el-table .cell {
  line-height: normal;
}
</style>