<template>
  <div class="cishu_warp">
    <div class="subject_wrap">
      <SubjectCard ref="SubjectCardRef"
                   @initList="getInfo" />
    </div>

    <div style="text-align: center;">
      <el-table :data="item"
                border
                style="width: 100%;min-height:200rem">
        <!-- height="900rem" -->

        <el-table-column prop="questionTypeName"
                         align="center"
                         label="题型" />
        <!-- <el-table-column align="center"
                         label="子题型">
          <template slot-scope="">
            /
          </template>
        </el-table-column> -->
        <el-table-column align="center"
                         label="训练次数">
          <template slot-scope="{row}">
            <span style='color:#2196F3'>{{row.thisCount}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="得分率(%)">
          <template slot-scope="{row}">
            <div style='display:flex'>
              <div class="line"
                   style="calc(100% - 105rem)">
                <div class="this_progress"
                     :style="{'width':row.thisRate+ '%'} "></div>

              </div>
              <div class="txt">{{row.thisRate}}</div>
            </div>
            <div style='display:flex'>
              <div class="line"
                   style="calc(100% - 105rem)">
                <div class="last_progress"
                     :style="{'width':row.lastRate+ '%'} "></div>
              </div>
              <div class="txt">{{row.lastRate}}</div>
            </div>
          </template>

        </el-table-column>
        <el-table-column align="center"
                         label="得分率变化(%)">
          <!-- prop="change_score" -->
          <template slot-scope="{row}">
            <i class="el-icon-bottom"
               v-if="row.change_score < 0"
               style="color:#FF6147"></i>
            <i class="el-icon-top"
               v-if="row.change_score > 0"
               style="color:#3DDB91"></i>
            <i class="el-icon-minus"
               v-if="row.change_score == 0"
               style="color:#37A0F4"></i>
            {{row.change_score!=0?Math.abs(row.change_score):''}}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import SubjectCard from '@/views/components/subject_card/indexCp.vue'
import { getQuestionTypeWeak } from '@/api/analysis.js'
export default {
  data () {
    return {
      item: []
    }
  },
  components: {
    SubjectCard
  },
  methods: {
    async getInfo (id) {
      // this.$http({
      //   url: '/api/v1/report/stat_question_type',
      //   method: 'get',
      //   params: this.$getTimeParams(id, this.$parent.value1, this.$parent.value2)
      // }).then(res => {
      //   this.item = res.data.list.weak
      // })
      const { data } = await getQuestionTypeWeak(this.$getTimeParams(id, this.$parent.value1, this.$parent.value2))
      this.item = data
      this.item.forEach(i => {
        i.change_score = i.thisRate - i.lastRate
      })
    },
    refresh () {

      this.$refs.SubjectCardRef.refresh()
    },
    format (value) {
      return value
    },
    getRate (item) {
      // console.log(item)
      // if (Number(item) < 0) {
      //   console.log("aa")
      //   return '0'
      // } else {
      //   return item
      // }
    }

  }
}
</script>

<style lang="scss" scoped>
.cishu_warp {
  width: 95%;
  margin-left: 2.5%;
  overflow-x: hidden;
}
.subject_wrap {
  width: 100%;
}
.line {
  width: 75%;
  height: 16rem;
  margin: 10rem 2rem;
  overflow: hidden;
  border-radius: 10rem;
  background: #f6f6f6;
  .this_progress,
  .last_progress {
    height: 16rem;
    border-radius: 15rem;
  }
  .this_progress {
    background: #2196f3;
  }
  .last_progress {
    background: #fbd46f;
  }
}
.txt {
  width: 25%;
  margin-left: 15rem;
  text-align: left;
}

::v-deep .el-table th,
.el-table tr {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 20rem;
}
::v-deep .el-table {
  margin-top: 10rem;
}

::v-deep .el-table {
  margin-top: 10rem;
  font-size: 24rem !important;
}
::v-deep .el-table__empty-text {
  line-height: 80rem;
}
</style>