<template>
  <div class="subject_search">
    <div class="subject">
      <div v-for="(item,index) in list"
           :class="{'active_subject':subjectId == item.subject_id}"
           @click="checkSubject(item,index)"
           :key="item.subject_id">
        {{item.subject_name}}
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      subjectId: '',
      index: 0,
      subject_name: ''
    }
  },
  watch: {
    list: {
      handler () {
        if (this.list.length) {
          this.subject_name = this.subjectList[0].subject_name
          this.subjectId = this.subjectList[0].subject_id
          this.$emit('initList', this.subjectId, this.subject_name)
        }

      },
      deep: true
    }
  },
  computed: {
    ...mapState(['subjectList']),
    list () {
      return this.subjectList.filter(item => item.subject_id != 13)
    }
  },
  methods: {
    ...mapMutations(['SET_SUBJECT']),
    checkSubject (item, index) {
      this.index = index
      this.subjectId = item.subject_id
      this.subject_name = item.subject_name
      this.$emit('initList', this.subjectId, item.subject_name)
    },
    async refresh () {
      if (!this.subjectId) {
        await this.getSubjectList()
      }
      else {
        this.$emit('initList', this.subjectId, this.subject_name)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.subject_search {
  display: flex;
  height: 60rem;
  justify-content: center;
  background: #ffffff;
  align-items: center;
  // border-bottom: 2rem #e5e5e5 solid;
  box-shadow: inset 0px -1px 0px 0px #cedaee;
  .subject {
    flex: 1;
    display: flex;
    justify-content: center;

    div {
      font-size: 20rem;
      font-weight: bold;
      color: #333333;
      padding: 0 14rem;
      margin: 0 20rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60rem;
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;
    color: #2196f3 !important;
    z-index: 1999;
  }
}
</style>