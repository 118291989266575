<template>
  <div class="average_warp">
    <div class="left_biao">
      得分率（%）
    </div>
    <div v-for="(item,index) in info"
         class="warp"
         :key="index">
      <div class="line"
           style="calc(100% - 105rem)">
        <div class="progress"
             :style="{'width':getRate(item)+ '%'} "></div>
      </div>
      <div class="text">
        {{getRate(item)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      color: ['#67B8F8', '#5DEEDC', '#F9A66D', '#FBD46F', '#F36C6C', '#8771FD'],
      info: []
    }
  },
  mounted () {
  },
  methods: {
    format (percentage) {
      return percentage
    },
    getInfo (info) {
      this.info = info
    },
    getRate (item) {
      if (Number(item.scoreRateNew) < 0) {
        return '0'
      } else {
        return item.scoreRateNew
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  position: relative;

  .left_biao {
    width: 100%;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 24rem;
    // text-indent: 5em;
    margin-left: 35rem;
    margin-bottom: 48rem;
  }
}

.warp {
  display: flex;
  align-items: center;
  margin: 0 5rem 34rem 35rem;
  .line {
    height: 16rem;
    flex: 4;
    overflow: hidden;
    border-radius: 10rem;
    background: #f6f6f6;

    .progress {
      height: 16rem;
      background: #2196f3;
      border-radius: 15rem;
    }
  }
  .text {
    // width: 60rem;
    font-size: 22rem;
    font-weight: 700;
    margin-left: 10rem;
    flex: 1;
  }
}
</style>